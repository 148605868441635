import React, { useEffect } from "react"
import aboutCover1 from "../images/aboutIcon1.svg"
import aboutCover2 from "../images/aboutIcon2.svg"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Parallax from "parallax-js"

const About = () => {
  useEffect(() => {
    parallaxIcon()
  })

  function parallaxIcon() {
    const scene = document.getElementById("aboutIllustration")
    const parallaxInstance = new Parallax(scene)
    console.log(parallaxInstance)
  }
  return (
    <Layout>
      <SEO title="Parteaguas" />
      <div className="querida">
        <div data-sal="slide-right" data-sal-delay="600" data-sal-easing="ease">
          <div id="aboutIllustration">
            <div data-depth="0.3">
              <img
                className="about-illustration"
                src={aboutCover1}
                alt="Parteaguas"
              />
            </div>
            <div data-depth="0.4">
              <img
                className="about-illustration"
                src={aboutCover2}
                alt="Parteaguas"
              />
            </div>
          </div>
        </div>
        <div
          className="about-intro"
          data-sal="slide-left"
          data-sal-delay="600"
          data-sal-easing="ease"
        >
          <h3 className="querida-intro__title">
            Aquí encuentra tu refugio. Un descanso del incesante ruido exterior.
            El espacio para tu crecimiento personal.
          </h3>
          <div>
            <p className="querida-intro__paragraph">
              Soy Celina Canales, periodista multimedia independiente. Estudié
              mi maestría en la Universidad de Columbia donde me gradué con el
              premio Henry N. Taylor. He colaborado con la BBC desde Nueva York
              y el Miami Herald en Miami. Trabajé durante siete años como
              editorialista para El Norte, parte de Grupo Reforma. Tengo un
              esposo, tres hijos, y aún no me convencen del perro. Parteaguas
              nace con la esperanza de que a través de los testimonios de gente
              ordinaria (y no tan ordinaria) podamos aprender acerca de la vida.
              Aprender del dolor, del sufrimiento, pero también de la valentía
              que todos llevamos dentro y del poder que hay en el amor.
            </p>
          </div>
          <div className="aboutSignature">
            <p>Celina Canales</p>
            <p>
              <span>Fundadora / Editora</span>
            </p>
            <br />
            <a href="mailto:hola@parteaguas.org">hola@parteaguas.org</a>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default About
