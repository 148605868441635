import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Helmet from "react-helmet"
import Header from "./header"
import "./layout.scss"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Helmet>
        <title>Parteaguas</title>
        <meta property="og:type" content="website" />
        <meta property="og:url" content="http://parteaguas.org/" />
        <meta property="og:title" content="Parteaguas" />
        <meta
          property="og:description"
          content="Ésa gran sacudida en tu vida que marca un antes y un después. Adversidad. Cambio. Resiliencia."
        />
        <meta property="og:image" content="./index.png" />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="http://parteaguas.org/" />
        <meta property="twitter:title" content="Parteaguas" />
        <meta
          property="twitter:description"
          content="Ésa gran sacudida en tu vida que marca un antes y un después. Adversidad. Cambio. Resiliencia."
        />
        <meta property="twitter:image" content="./index.png" />
      </Helmet>
      <Header siteTitle={data.site.siteMetadata.title} />
      <div>
        <main>{children}</main>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
